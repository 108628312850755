/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class ProductHasCategory extends BaseModel {
    static entityName = 'product-has-category';

    defaults() {
        return {
            product_id: null,
            category_id: null,
            display_order: 1,
            is_preferred: 0
        }
    }

    mutations() {
        return {
            id: (id) => `${this.product_id},${this.category_id}`
        };
    }
}
