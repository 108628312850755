/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class ProductProperty extends BaseModel {
    static entityName = 'product-property';

    defaults() {
        return {
            ...super.defaults(),
            name: null
        }
    }
}
